import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastService, IxActiveModal, ModalService } from '@siemens/ix-angular';
import { AppState } from 'src/app/store/app.state';
import { CompanyService } from 'src/services/company/company.service';
import * as fromCompanySelector from "src/app/store/selectors/company.selector";
import { PasswordService } from 'src/services/password/password.service';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  value = "";
  wasValidated = false;
  updateUserForm!: FormGroup;
  submitted = false;
  comanyId: string = "";
  editData: any;
  userData!: any;

  constructor(
    private toastService: ToastService,
    private readonly activeModal: IxActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private passwordService: PasswordService,
  ) {
    this.userData = this.activeModal.data.data;
  }

  ngOnInit(): void {
    this.updateUserForm = this.fb.group(
      {
        firstName: new FormControl(this.userData.FirstName, {
          validators: [Validators.required],
        }),
        lastName: new FormControl(this.userData.LastName, {
          validators: [Validators.required],
        }),
        title: new FormControl(this.userData.Title, {
          validators: [Validators.required],
        }),
        userSociety: new FormControl(this.userData.Society, {
          validators: [Validators.required],
        }),
        email: new FormControl(this.userData.Email),

        countryCode: new FormControl(this.userData.CountryCode, {
          validators: [Validators.required, Validators.pattern(/^\+\d{1,3}$/)],
        }),
        mobilePhone: new FormControl(this.userData.Mobile, {
          validators: [Validators.required, Validators.pattern(/^\d{10}$/)],
        }),
        language: new FormControl(this.userData.Language, {
          validators: [Validators.required],
        }),
        password: new FormControl(this.userData.Password, {
          validators: [Validators.minLength(8)],
        }),
        confirmPassword: new FormControl(this.userData.Password,{
          validators: [this.passwordValidator],
        })
      },
      {
        validators: [this.passwordsMatchValidator],
      }
    );
  }

  // Custom validator to ensure confirmPassword is required if password is set and vice versa
  passwordsMatchValidator(formGroup: AbstractControl): ValidationErrors | null {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (password && !confirmPassword) {
      return { confirmPasswordRequired: true };
    }

    if (confirmPassword && !password) {
      return { passwordRequired: true };
    }

    return null;
  }


  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
    const isLengthValid = value.length >= 8;

    if (isLengthValid && hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar) {
      return null;
    } else if (!hasSpecialChar) {
      return { passwordNoSpecialChar: true };
    } else {
      return { passwordInvalid: true };
    }
  }

  get updateUserFormControl() {
    return this.updateUserForm.controls;
  }
  create(event: any) {
    event.preventDefault();
    this.wasValidated = true;
    this.submitted = true;
    if (this.updateUserForm.valid) {
      let user: UpdateUser = {
        first_name: this.updateUserForm.get("firstName")?.value,
        last_name: this.updateUserForm.get("lastName")?.value,
        society: this.updateUserForm.get("userSociety")?.value,
        mobile: this.updateUserForm.get("mobilePhone")?.value,
        country_code: this.updateUserForm.get("countryCode")?.value,
        language: this.updateUserForm.get("language")?.value,
        title: this.updateUserForm.get("title")?.value
      };

      let password = {
        password: this.updateUserForm.get("password")?.value,
        confirmPassword: this.updateUserForm.get("confirmPassword")?.value
      }

      if (!this.validateUserData(this.updateUserForm)) return;

      try {
        this.companyService
          .updateUser(user, this.userData.CompanyId, this.userData.UserId)
          .subscribe(
            (data: any) => {
              if (data) {
                this.toastService.setPosition("top-right");

                this.toastService.show({
                  type: "success",
                  message: data.message,
                });
                this.activeModal.close(this.companyService.subject.next(""));
              }
            },
            (err: HttpErrorResponse) => {
              this.toastService.setPosition("top-right");

              this.toastService.show({
                type: "error",
                message: err.error.message,
              });


            }
          );
        if (password) {
          this.passwordService.changePassword({
            username: this.userData.Email,
            oldPassword: this.userData.Password,
            newPassword: password.password
          }).subscribe(
            (data: any) => {
              if (data) {
                this.toastService.setPosition("top-right");

                this.toastService.show({
                  type: "success",
                  message: data.message,
                });
                this.activeModal.close(this.companyService.subject.next(""));
              }
            },
            (err: HttpErrorResponse) => {
              this.toastService.setPosition("top-right");

              this.toastService.show({
                type: "error",
                message: err.error.message,
              });
            }
          )
        };
      } catch (error) {
        this.handleErrorWhileCreatingUser(error);
      }
    }
  }

  handleErrorWhileCreatingUser = (error: any) => {
    this.toastService.setPosition("bottom-right");
    this.toastService.show({
      type: "error",
      message: "An error occurred while creating the user",
    });
  };


  validateUserData = (userData: FormGroup): boolean => {

    const phoneRegex = /^\d{10}$/;
    if (
      userData.get("mobilePhone")?.value !== "" &&
      !phoneRegex.test(userData.get("mobilePhone")?.value)
    ) {
      this.toastService.setPosition("top-right");
      this.toastService.show({
        type: "error",
        message: "The mobile number is invalid",
      });
      return false;
    }
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    return true;
  };

  close() {
    this.activeModal.close("My close response");
  }

}
export interface User {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  society: string;
  mobile: string;
  country_code: string;
  language: string;
  title: string;
  role: string;
  companyId: string;
}

export interface UpdateUser {

  first_name: string;
  last_name: string;
  society: string;
  mobile: string;
  country_code: string;
  language: string;
  title: string;
}

